$primary : #d6156c;
$black: #2b2825;
header {
  background-color: #fff;
  height:120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  a{
    height:100%;
    img {
      max-width: 100%;;
      height: 100%;
    }
  }
}
.projects {
  padding:1em;
  background-color: $primary;
  .react-photo-album--column {
    img {
      border-radius: 1em;
    }
  }
}
.clients {
  padding:1em;
  background-color: $black;
    margin-bottom: -3vw;
  h2{
    color:white;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1em;
    width: 100%;
    line-height: 1em;
    margin:0;
    padding:0;
    text-align: center;
    padding-right: 1em;
    margin-bottom: -1vw;
    z-index: 2;
    position: relative;
    opacity: .3;
    margin-top:1em
  }
  .clients-list{
    z-index: 1;
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    
    @media screen and (max-width:1210px) {
      padding-bottom:20vw;
    }
    img {
      width:8vw;
      @media screen and (max-width:1210px) {
        width:20vw;
        margin-bottom:-10vw;
      }
      padding:1em;
    }
  }
}